<template>
  <div>
    <base-dialog ref="baseDialog" @cancel="cancel" @confirm="confirm">
      <form-list
        class="assignFormList"
        ref="formList"
        :config="config"
        :formData="formData"
        @openDialog="openDialog"
      ></form-list>
      <div
        v-if="feeModeName"
        style="font-size: 12px; color: #0d69b9; padding-bottom: 10px"
      >
        {{ $t('assignDistribute.feeMode') }}：{{ feeModeName }}
      </div>
      <table-list
        :isDefine="true"
        :columns="columns"
        :showSort="false"
        :defaultContent="defaultContent"
      >
        <template v-slot:assignNumber="{ row }">
          <el-input
            v-model="row.assignNumber"
            :placeholder="$t('assignDistribute.assignNumberTips')"
            @input="changeNumber($event, row)"
            clearable
          />
        </template>
      </table-list>
      <p style="color: #c0c4cc; padding-top: 15px; font-size: 12px">
        {{ $t('assignDistribute.assignTips') }}
      </p>
    </base-dialog>
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="dialogTableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      :defaultParams="dialogDefaultParams"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 授权数量
      columns: [
        {
          label: this.$t('assignDistribute.assignName'),
          prop: 'assignName',
        },
        {
          label: this.$t('assignDistribute.remainNumber'),
          prop: 'remainNumber',
        },
        {
          label: this.$t('assignDistribute.assignNumber'),
          prop: 'assignNumber',
        },
      ],
      defaultContent: [],
      serviceResource: null,
      level: '',
      feeModeName: '',
      resourceFeeModeEnumObj: this.$util.listToObj(
        this.$dict.resourceFeeModeEnum()
      ),
      // 表单配置项
      config: [
        // lang:企业信息
        {
          tag: 'dialog-define',
          label: this.$t('assignDistribute.targetCompany'),
          prop: 'company',
          hidden: false,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
      ],
      formData: {},
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: null,
    }
  },
  watch: {
    'formData.company'(val) {
      if (!val) {
        this.feeModeName = ''
      } else {
        this.selectCompany(val)
      }
    },
  },
  mounted() {
    this.findMyCompany()
  },
  methods: {
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('assignDistribute.targetCompany')
      this.dialogUrl =  this.$api.companyFind
      this.dialogDefaultParams = {
        productType : this.$util.getLocalStorage('productType')
      }
      this.dialogColumns = [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
        },
        // lang:行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          callback(row) {
            return row && row.industry && row.industry.value
          },
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
          callback(row) {
            return row && row.address && row.address.value
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
    // 查询当前企业信息
    findMyCompany() {
      const {  productConfigEntities, company } = this.$store.getters
      const {feeMode, feeModeStr} = productConfigEntities.filter(item => item.type === 'TMVA')[0]
      // 查询当前企业信息
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.serviceResourceFindByCompany,
        data: {
          companyUid: company.uid,
        },
      }).then((data) => {
        console.log(data)
        this.serviceResource = data || {}
        this.level = company.level
        let showFeeMode = ''
        console.log('level', this.level)
        if (this.level === 'ONE' || this.level === 'TWO') {
          if (this.level === 'ONE')
            showFeeMode = 'METERING,ACCOUNT_SUBSCRIPTIONS'
          else {
            showFeeMode = feeModeStr
          }
        } else {
          showFeeMode = feeMode
        }
        console.log('showFeeMode', showFeeMode)
        this.getDefaultContent(showFeeMode)
      })
    },
    // 选择授权企业
    selectCompany(company) {
      let { level, feeModeStr, feeMode } = company
      if (level === 'TWO') {
        this.getDefaultContent(feeModeStr)
        feeModeStr = feeModeStr.split(',')
        this.feeModeName = feeModeStr
          .map((item) => this.resourceFeeModeEnumObj[item])
          .join(',')
      } else {
        this.getDefaultContent(feeMode)
        this.feeModeName = this.resourceFeeModeEnumObj[company.feeMode]
      }
    },
    // 获取授权内容
    getDefaultContent(showFeeMode) {
      const {
        remainAccountNumber,
        remainVideoCallDuration,
        remainAccountSubscriptionsNumber,
        remainEquipmentNumber,
        remainEquipmentComponentsNumber,
        remainArDigitalDisplayNumber,
        remainCloudRecordingStorageSpace,
        remainOssStorageSpace,
        remainProductKeyNumber,
        remainThermalImageryFunctionKeyNumber,
        remainTeamViewerFunctionKeyNumber,
        remainSensorCollectFunctionKeyNumber,
        remainEndoscopeFunctionKeyNumber,
        remainWebEditProductKeyNumber,
      } = this.serviceResource
      const defaultContent = this.$dict.resourceTypeEnumSelect(this.$util.getLocalStorage('apiprefix')).map((item) => {
        let remainNumber = 0
        if (this.level === 'ONE') {
          remainNumber = '9999+'
        } else {
          switch (item.value) {
            case 'ACCOUNT_NUMBER': // 账号数量(个)
              remainNumber = remainAccountNumber
              break
            case 'VIDEO_CALL_DURATION': // 通话时长(分钟)
              remainNumber = +parseFloat(remainVideoCallDuration / 60).toFixed(
                2
              )
              break
            case 'ACCOUNT_SUBSCRIPTIONS_NUMBER': // 账号订阅数量(个)
              remainNumber = remainAccountSubscriptionsNumber
              break
            case 'EQUIPMENT_NUMBER': // 设备码数量(个)
              remainNumber = remainEquipmentNumber
              break
            case 'EQUIPMENT_COMPONENTS_NUMBER': // 设备备件数量(个)
              remainNumber = remainEquipmentComponentsNumber
              break
            case 'AR_DIGITAL_DISPLAY_NUMBER': // AR数显数量(个)
              remainNumber = remainArDigitalDisplayNumber
              break
            case 'CLOUD_RECORDING_STORAGE_SPACE': // 云录制空间(G)
              remainNumber = +parseFloat(
                remainCloudRecordingStorageSpace / 1024 / 1024 / 1024
              ).toFixed(4)
              break
            case 'OSS_STORAGE_SPACE': // 云存储空间(G)
              remainNumber = +parseFloat(
                remainOssStorageSpace / 1024 / 1024 / 1024
              ).toFixed(4)
              break
             case 'PRODUCT_KEY_NUMBER': // 产品密钥数量
              remainNumber = remainProductKeyNumber
              break
            case 'THERMAL_IMAGERY_FUNCTION_KEY_NUMBER': // 热成像功能密钥数量
              remainNumber = remainThermalImageryFunctionKeyNumber
              break
            case 'TEAM_VIEWER_FUNCTION_KEY_NUMBER': // 远程控制功能密钥数量
              remainNumber = remainTeamViewerFunctionKeyNumber
              break
            case 'SENSOR_COLLECT_FUNCTION_KEY_NUMBER': // 传感器采集功能密钥数量
              remainNumber = remainSensorCollectFunctionKeyNumber
              break
            case 'ENDOSCOPE_FUNCTION_KEY_NUMBER': // 内窥镜功能密钥数量
              remainNumber = remainEndoscopeFunctionKeyNumber
              break
            case 'WEB_EDIT_PRODUCT_KEY_NUMBER': // 内窥镜功能密钥数量
              remainNumber = remainWebEditProductKeyNumber
              break
            default: // do something
          }
        }
        return {
          id: item.value,
          assignName: item.label,
          remainNumber: remainNumber,
          assignNumber: '',
          type: item.value,
        }
      })
     this.defaultContent = defaultContent.filter((item) => {
        if (
          showFeeMode.indexOf('METERING') >= 0 &&
          showFeeMode.indexOf('ACCOUNT_SUBSCRIPTIONS') >= 0
        ) {
          return true
        } else if (showFeeMode.indexOf('METERING') >= 0) {
          if (item.type !== 'ACCOUNT_SUBSCRIPTIONS_NUMBER') return true
        } else {
          if (item.type !== 'VIDEO_CALL_DURATION') return true
        }
      })
    },
    // 输入数量
    changeNumber(e, row) {
      if (!e) return
      this.$util.debounce(() => {
        // 授权企业验证
        if (!this.formData.company) {
          row.assignNumber = ''
          this.$element.showMsg(
            this.$t('assignDistribute.companySelectTips'),
            'warning'
          )
          return
        }
        if (!(/^\d+$/.test(e) && Number(e) >= 0)) {
          row.assignNumber = ''
          this.$element.showMsg(
            this.$t('assignDistribute.assignValidateTips'),
            'warning'
          )
        } else {
          if (this.level !== 'ONE') {
            if (+e > row.remainNumber)
              row.assignNumber = +parseFloat(row.remainNumber).toFixed(0)
          }
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const { company } = this.formData
      // 授权企业验证
      if (!company) {
        this.$element.showMsg(
          this.$t('assignDistribute.companySelectTips'),
          'warning'
        )
        return
      }
      // 授权内容验证
      const assignDetailList = []
      this.defaultContent.forEach((item) => {
        if (item.assignNumber) {
          let assignNumber = +item.assignNumber
          // 通话时长
          if (item.type === 'VIDEO_CALL_DURATION') {
            assignNumber = +parseFloat(assignNumber * 60).toFixed(0)
          } else if (
            item.type === 'CLOUD_RECORDING_STORAGE_SPACE' ||
            item.type === 'OSS_STORAGE_SPACE'
          ) {
            assignNumber = +parseFloat(
              assignNumber * 1024 * 1024 * 1024
            ).toFixed(0)
          }
          assignDetailList.push({
            type: item.type,
            assignNumber: assignNumber,
          })
        }
      })
      if (!assignDetailList.length) {
        this.$element.showMsg(
          this.$t('assignDistribute.assignCommitTips'),
          'warning'
        )
        return
      }
      // 提交参数
      const params = {
        targetCompanyUid: company.uid,
        // targetCompany: company,
        assignDetailList,
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.serviceResourceAssignAssign,
        data: params,
      }).then(() => {
        // lang:授权成功
        this.$element.showMsg(
          this.$t('assignDistribute.assignSuccess'),
          'success'
        )
        this.$emit('closePage', 'add')
      })
    },
  },
}
</script>
